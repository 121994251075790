body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.btn-actions {
    .custom-btn-box {
        padding: 4px;
    }
}

.CST-form {
    .line-title {
        font-size: 20px;
        padding: 8px 0;
        display: flex;
        border-top: 1px solid rgba($color: #d9d9d9, $alpha: 0.6);
        margin-top: 8px;
    }
}
